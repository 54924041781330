import React, { createContext, useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

export type MousePosition = { x: number; y: number };
export const MouseContext = createContext<MousePosition>({ x: 0, y: 0 });
export const useMouse = () => useContext(MouseContext);

export const MouseProvider = ({ children }: any) => {
    const [mousePosition, setMousePosition] = useState<MousePosition>({
        x: 0,
        y: 0,
    });

    useEffect(() => {
        if (!isMobile) {
            const updatePosition = (e: MouseEvent) =>
                setMousePosition({
                    x: e.clientX,
                    y: e.clientY,
                });

            window.addEventListener('mousemove', updatePosition);

            return () =>
                window.removeEventListener('mousemove', updatePosition);
        }
    }, []);

    return (
        <MouseContext.Provider value={mousePosition}>
            {children}
        </MouseContext.Provider>
    );
};
