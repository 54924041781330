import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { useScroll } from '../../providers/ScrollProvider';
import { scrollToTop } from '../../utils/Utils';

const FloatingNav = () => {
    const [leftOffset, setLeftOffset] = useState(-200);

    const location = useLocation();
    const scroll = useScroll();

    useEffect(() => {
        const header = document.getElementById('header');
        const floatingNav = document.getElementById('floating-nav');

        if (header && floatingNav) {
            if (scroll > header.offsetHeight + 25) {
                setLeftOffset(0);
            } else {
                setLeftOffset(-floatingNav.offsetWidth);
            }
        }
    }, [scroll]);

    return (
        <div
            id="floating-nav"
            className="floating-nav hide-on-mobile"
            style={{
                left: leftOffset,
                opacity: leftOffset === 0 ? 1 : 0,
            }}
        >
            <Stack direction="column" spacing={1}>
                <div
                    onClick={scrollToTop}
                    className="logo"
                    style={{ width: 66 }}
                />

                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={4}
                >
                    <NavLink
                        end
                        to="/"
                        state={{ from: location.pathname }}
                        className={({ isActive }) =>
                            isActive ? 'nav-link-active' : ''
                        }
                    >
                        About
                    </NavLink>
                    <NavLink
                        to="/work"
                        state={{ from: location.pathname }}
                        className={({ isActive }) =>
                            isActive ? 'nav-link-active' : ''
                        }
                    >
                        Work
                    </NavLink>
                    <NavLink
                        to="/contact"
                        state={{ from: location.pathname }}
                        className={({ isActive }) =>
                            isActive ? 'nav-link-active' : ''
                        }
                    >
                        Contact
                    </NavLink>
                </Stack>
            </Stack>
        </div>
    );
};

export default FloatingNav;
