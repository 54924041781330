import React, { createContext, useContext, useEffect, useState } from 'react';

export const ScrollContext = createContext<number>(0);
export const useScroll = () => useContext(ScrollContext);

export const ScrollProvider = ({ children }: any) => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => setScrollPosition(window.scrollY);

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <ScrollContext.Provider value={scrollPosition}>
            {children}
        </ScrollContext.Provider>
    );
};
