import { createTheme } from '@mui/material';

import cursor from '../assets/images/hex-cursor.webp';
import colors from './scss/_colors.scss';
import fonts from './scss/_fonts.scss';
import styles from './scss/_styles.scss';

export const theme = createTheme({
    palette: {
        primary: {
            main: colors['primary-500'],
        },
        error: {
            main: colors['error'],
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {
        fontSize: 16,
        fontFamily: ['SF Compact Display'].join(','),
    },
    components: {
        MuiInput: {
            styleOverrides: {
                root: {
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingTop: 10,
                    paddingBottom: 10,
                    margin: 0,
                    border: 'none',
                    color: colors['text'],
                    backgroundColor: colors['surface-400'],
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                shrink: {
                    transform: 'translate(0, -13px) scale(0.75)',
                },
                root: {
                    marginTop: 11,
                    marginLeft: 15,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    padding: '0 !important',
                    backgroundColor: 'none',
                    borderRadius: 0,

                    '&&:hover': {
                        fieldset: {
                            borderColor: colors['surface-600'],
                        },
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: colors['text'],

                    fieldset: {
                        borderLeft: 'none',
                        borderRight: 'none',
                        borderTop: 'none',
                        borderBottom: `1.4px solid ${colors['surface-100']}`,
                        transition: styles['transitions-in'],
                    },
                },
                input: {
                    paddingLeft: '0 !important',
                    paddingRight: '0 !important',
                    fontFamily: fonts['body'],
                    fontWeight: 400,
                    caretColor: colors['primary-500'],
                    backgroundClip: 'padding-box',

                    '&::placeholder': {
                        textOverflow: 'ellipsis !important',
                        color: colors['surface-600'],
                        opacity: 1,
                    },
                },
                inputMultiline: {
                    fontFamily: fonts['body'],
                    fontSize: '1rem',
                    paddingLeft: 4,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                startIcon: {
                    svg: {
                        width: 26,
                        height: 26,
                        fill: colors['primary-500'],
                    },
                    marginRight: 15,
                    padding: 0,
                },
                text: {
                    fontFamily: fonts['body'],
                    fontWeight: 500,
                    fontSize: '1.7em',
                    lineHeight: 1.3,
                    letterSpacing: '-0.5px',
                    textTransform: 'none',
                },
                root: {
                    '&::before': {
                        display: 'none',
                    },
                    '&:hover': {
                        '& svg': {
                            fill: `${colors['background-darker']} !important`,
                        },
                        backgroundColor: colors['primary-500'],
                        color: `${colors['background-darker']} !important`,
                        borderColor: `${colors['primary-500']} !important`,
                        transition: styles['transitions-in'],
                    },
                    '&:active': {
                        color: `${colors['primary-500']} !important`,
                    },

                    width: '100%',
                    cursor: `url(${cursor}), crosshair`,
                    paddingLeft: 24,
                    paddingRight: 24,
                    paddingTop: 25,
                    paddingBottom: 25,
                    borderRadius: 0,
                    color: colors['primary-500'],
                    backdropFilter: 'blur(10px)',
                    border: `1px solid ${colors['surface-100']}`,
                    backgroundColor: `transparent`,
                    transition: styles['transitions-out'],
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    color: colors['surface-600'],
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    WebkitAppearance: 'none',
                    '&& .Mui-error': {
                        color: colors['surface-500'],
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    '&&:hover': {
                        '&&::before': {
                            visibility: 'visible',
                        },
                        backgroundColor: colors['primary-200'],
                    },

                    '&&::before': {
                        visibility: 'hidden',
                    },
                    cursor: `url(${cursor}), crosshair`,
                    padding: 6,
                    borderRadius: styles['radii-sm'],
                    color: colors['primary-500'],
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontFamily: 'Menlo',
                    marginLeft: 0,
                    paddingTop: 6,
                    fontSize: '0.8rem',
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                icon: {
                    color: 'black',
                },
                root: {
                    color: colors['primary-500'],
                    fontFamily: 'Menlo',
                    fontWeight: 600,
                    fontSize: '0.74em',
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingTop: 4,
                    paddingBottom: 4,
                    borderRadius: 0,
                    boxShadow: 'none',
                },
            },
        },
    },
});
