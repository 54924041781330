import React, { useCallback } from 'react';
import TSParticles from 'react-tsparticles';
import { loadFull } from 'tsparticles';

import colors from '../assets/scss/_colors.scss';

const Particles = () => {
    const init = useCallback(async (engine: any) => {
        // console.log(engine);
        await loadFull(engine);
    }, []);

    return (
        <TSParticles
            className="particles"
            id="particles"
            init={init}
            options={{
                background: {
                    color: {
                        value: 'transparent',
                    },
                },
                fpsLimit: 60,
                interactivity: {
                    events: {
                        onClick: {
                            enable: true,
                            mode: 'push',
                        },
                        onHover: {
                            enable: true,
                            mode: 'repulse',
                        },
                        resize: true,
                    },
                    modes: {
                        push: {
                            quantity: 2,
                        },
                        repulse: {
                            distance: 180,
                            duration: 2000,
                        },
                    },
                },
                particles: {
                    color: {
                        value: [colors['primary-500'], colors['surface-100']],
                    },
                    links: {
                        color: colors['surface-100'],
                        distance: 300,
                        enable: true,
                        opacity: 1,
                        width: 1,
                    },
                    collisions: {
                        enable: false,
                    },
                    move: {
                        direction: 'none',
                        enable: true,
                        outModes: {
                            default: 'split',
                        },
                        random: false,
                        speed: 0.15,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 1000,
                        },
                        value: 30,
                    },
                    opacity: {
                        value: 1,
                    },
                    shape: {
                        type: 'square',
                    },
                    size: {
                        value: { min: 1, max: 3 },
                    },
                },
                detectRetina: true,
            }}
        />
    );
};

export default Particles;
