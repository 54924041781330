import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { useScroll } from '../../providers/ScrollProvider';

const MobileNav = () => {
    const location = useLocation();
    const scroll = useScroll();

    const [bottomOffset, setBottomOffset] = useState(-200);

    useEffect(() => {
        const header = document.getElementById('header');

        if (header && scroll >= header.offsetHeight + 16) {
            setBottomOffset(0);
        } else {
            setBottomOffset(-200);
        }
    }, [scroll]);

    return (
        <div
            id="mobile-nav"
            className="mobile-nav hide-on-desktop"
            style={{
                bottom: bottomOffset,
            }}
        >
            <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
            >
                <NavLink
                    end
                    to="/"
                    state={{ from: location.pathname }}
                    className={({ isActive }) =>
                        isActive ? 'nav-link-active' : ''
                    }
                >
                    About
                </NavLink>
                <NavLink
                    to="/work"
                    state={{ from: location.pathname }}
                    className={({ isActive }) =>
                        isActive ? 'nav-link-active' : ''
                    }
                >
                    Work
                </NavLink>
                <NavLink
                    to="/contact"
                    state={{ from: location.pathname }}
                    className={({ isActive }) =>
                        isActive ? 'nav-link-active' : ''
                    }
                >
                    Contact
                </NavLink>
            </Stack>
        </div>
    );
};

export default MobileNav;
