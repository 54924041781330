import { Grid, Stack } from '@mui/material';
import React from 'react';

import type { DevProject } from '../../../../utils/Types';

type Props = {
    project: DevProject;
};

export const DevProjectCard = ({ project }: Props) => {
    return (
        <Grid
            item
            className="dev-project-card"
            xs={6}
            onClick={() =>
                project.url ? window.open(project.url, '_blank') : null
            }
            style={{ cursor: project.url ? 'pointer' : 'default' }}
        >
            <Stack spacing={2}>
                <div className="dev-project-preview">
                    <img src={project.preview} />
                </div>
                <h2>{project.title}</h2>
                <div className="dev-project-skills">
                    <ul>
                        {project.skills.map((skill, i) => (
                            <li key={i}>{skill}</li>
                        ))}
                    </ul>
                </div>
                <p style={{ marginTop: 30 }}>{project.description}</p>
            </Stack>
        </Grid>
    );
};

export default DevProjectCard;
