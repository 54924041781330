import { Link, Stack } from '@mui/material';
import React from 'react';

import { LogoGithub } from '@carbon/icons-react';
import { My } from '../data/My';
import { Divider } from './Divider';

const Footer = () => {
    return (
        <div className="footer">
            <Divider interactive />

            <Stack
                direction="row"
                justifyContent={{ xs: 'center', md: 'flex-end' }}
                alignItems={{ xs: 'center', md: 'center' }}
                spacing="12px"
                mt={{ xs: 5, md: 1 }}
            >
                <p>&copy; {`${new Date().getFullYear()} ${My.name}`}</p>
                <p>
                    <Link href={`https://github.com/${My.github}`}>
                        <LogoGithub
                            style={{ verticalAlign: -3, paddingRight: 5 }}
                        />
                        <span style={{ fontWeight: 400 }}>{My.github}</span>
                    </Link>
                </p>
            </Stack>
        </div>
    );
};

export default Footer;
