import { Stack } from '@mui/material';
import React from 'react';

type HeroProps = {
    header: JSX.Element;
    body: JSX.Element;
};
const Hero = ({ header, body }: HeroProps) => {
    return (
        <div className="hero muted">
            <Stack
                direction={{
                    xs: 'column-reverse',
                    md: 'row',
                }}
                alignItems={{
                    xs: 'center',
                    md: 'flex-start',
                }}
                justifyContent="space-between"
                spacing={{
                    xs: 2,
                    md: 15,
                }}
            >
                <Stack width="100%">
                    {header}
                    {body}
                </Stack>
            </Stack>
        </div>
    );
};

export default Hero;
