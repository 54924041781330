import { ArrowUp as ArrowIcon } from '@carbon/icons-react';
import { IconButton } from '@mui/material';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import { useScroll } from '../providers/ScrollProvider';
import { scrollToTop } from '../utils/Utils';

const BackToTop = () => {
    const scroll = useScroll();

    const HIDE_ON_MOBILE_TRIGGER = 400;
    const HIDE_ON_DESKTOP_TRIGGER = 600;
    const BOTTOM_OFFSET = '20px';

    useEffect(() => {
        const backToTopButton = document.getElementById('back-to-top');

        if (backToTopButton) {
            const BUTTON_HEIGHT = `-${backToTopButton.offsetHeight}px`;

            if (isMobile) {
                if (scroll > HIDE_ON_MOBILE_TRIGGER) {
                    backToTopButton.style.bottom = '0px';
                } else {
                    backToTopButton.style.bottom = BUTTON_HEIGHT;
                }
            } else {
                if (scroll > HIDE_ON_DESKTOP_TRIGGER) {
                    backToTopButton.style.bottom = BOTTOM_OFFSET;
                } else {
                    backToTopButton.style.bottom = BUTTON_HEIGHT;
                }
            }
        }
    }, [scroll]);

    return (
        <div id="back-to-top" className="back-to-top">
            <IconButton aria-label="Back to top button" onClick={scrollToTop}>
                <ArrowIcon className="icon" size={32} />
            </IconButton>
        </div>
    );
};

export default BackToTop;
