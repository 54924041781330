/* spell-checker: disable */
/* eslint-disable */
import { isMobile } from 'react-device-detect';

export const scrollToTop = () =>
    window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
    });

export const constants: Record<string, any> = {
    buttonLeftOffset: isMobile ? 0 : -105,
};
