import React from 'react';

import { MouseProvider } from './MouseProvider';
import { ScrollProvider } from './ScrollProvider';

export const InteractionProvider = ({ children }: any) => {
    return (
        <ScrollProvider>
            <MouseProvider>{children}</MouseProvider>
        </ScrollProvider>
    );
};
