import { Button, Stack } from '@mui/material';
import React from 'react';
import Helmet from 'react-helmet';

import { LogoLinkedin } from '@carbon/icons-react';
import { EmailRounded } from '@mui/icons-material';
import { My } from '../../../data/My';
import Footer from '../../Footer';
import Hero from '../../Hero';

const ContactScreen = () => {
    return (
        <>
            <Helmet>
                <title>Yulian Kraynyak | Contact</title>
                <meta
                    property="og:title"
                    content="Yulian Kraynyak | Contact"
                    data-react-helmet="true"
                />
                <meta
                    name="description"
                    content={`Find me online or shoot an me an email, ${My.email}.`}
                    data-react-helmet="true"
                />
                <meta
                    property="og:image"
                    content="../../public/resources/meta-image.jpg"
                    data-react-helmet="true"
                />
            </Helmet>

            <Hero
                header={
                    <h1>
                        <strong>Let's talk</strong>
                    </h1>
                }
                body={<></>}
            />
            <Stack
                direction={{ xs: 'column', md: 'row' }}
                spacing={2}
                justifyContent="stretch"
                style={{
                    marginBottom: window.innerHeight - 600,
                }}
            >
                <Button
                    aria-label="email button"
                    href={`mailto:${My.email}`}
                    target="_blank"
                    startIcon={<EmailRounded />}
                >
                    {My.email}
                </Button>
                <Button
                    aria-label="linkedin button"
                    href={`https://linkedin.com/in/${My.linkedin}`}
                    target="_blank"
                    startIcon={<LogoLinkedin />}
                >
                    {`in/${My.linkedin}`}
                </Button>
            </Stack>
            <Footer />
        </>
    );
};

export default ContactScreen;
