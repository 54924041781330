import { Stack } from '@mui/material';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const HeaderNav = () => {
    const location = useLocation();

    return (
        <Stack
            className="header-nav"
            direction={{ xs: 'row', md: 'row' }}
            spacing="28px"
        >
            <NavLink
                end
                to="/"
                state={{ from: location.pathname }}
                className={({ isActive }) =>
                    isActive ? 'nav-link-active' : ''
                }
            >
                About
            </NavLink>
            <NavLink
                to="/work"
                state={{ from: location.pathname }}
                className={({ isActive }) =>
                    isActive ? 'nav-link-active' : ''
                }
            >
                Work
            </NavLink>
            <NavLink
                to="/contact"
                state={{ from: location.pathname }}
                className={({ isActive }) =>
                    isActive ? 'nav-link-active' : ''
                }
            >
                Contact
            </NavLink>
        </Stack>
    );
};

export default HeaderNav;
