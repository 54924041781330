import './assets/scss/App.scss';

import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import Header from './components/Header';
import Particles from './components/Particles';
import FloatingNav from './components/nav/FloatingNav';
import MobileNav from './components/nav/MobileNav';
import AboutScreen from './components/screens/about/AboutScreen';
import ContactScreen from './components/screens/contact/ContactScreen';
import WorkScreen from './components/screens/work/WorkScreen';
import { InteractionProvider } from './providers/InteractionProvider';
import { ThemeProvider } from './providers/ThemeProvider';

const App = () => {
    return (
        <InteractionProvider>
            <Router>
                <div className="App">
                    <ThemeProvider>
                        <Particles />
                        <FloatingNav />
                        <MobileNav />

                        <div id="wrapper" className="wrapper">
                            <Header />
                            <Routes>
                                <Route element={<AboutScreen />} />
                                <Route path="/" element={<AboutScreen />} />
                                <Route path="/about" element={<AboutScreen />} />
                                <Route path="/work" element={<WorkScreen />} />
                                <Route path="/contact" element={<ContactScreen />} />
                            </Routes>
                        </div>
                    </ThemeProvider>
                </div>
            </Router>
        </InteractionProvider>
    );
};

export default App;
