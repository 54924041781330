import { Grid, Stack } from '@mui/material';
import { extractColors } from 'extract-colors';
import { FinalColor } from 'extract-colors/lib/types/Color';
import React, { useEffect, useState } from 'react';

import type { VisualProject } from '../../../../utils/Types';

type Props = {
    project: VisualProject;
};

const VisualProjectCard = ({ project }: Props) => {
    const [colors, setColors] = useState<FinalColor[]>();

    // useEffect(() => {
    //     extractColors(project.url, {
    //         pixels: 1130,
    //         distance: 0.15,
    //         splitPower: 15,
    //         lightnessDistance: 0,
    //         saturationDistance: 0,
    //         hueDistance: 0.3,
    //     }).then((c) => setColors(c.slice(0, 5)));
    // }, []);

    const renderPalette = () => {
        if (colors) {
            const SCALING_FACTOR = 0.06;
            const DEFAULT_SIZE = 35;

            const container = document.getElementById(
                `visual-project-${project.title}`
            );
            const size = container
                ? container.clientWidth * SCALING_FACTOR
                : DEFAULT_SIZE;

            return colors.map((color: FinalColor, i) => {
                return (
                    <div
                        key={`visual-project-${project.title}-colors-${i}`}
                        style={{
                            width: size,
                            height: size,
                            backgroundColor: color['hex'],
                        }}
                    />
                );
            });
        }

        return null;
    };

    return (
        <Grid item xs={6} onClick={() => (window.location.href = project.url)}>
            <div className="visual-project-card">
                <Stack spacing={1} className="visual-project-card-overlay">
                    <h2>{project.title}</h2>
                    <label>
                        {project.medium.toString()}
                        {/* — {project.tools} */}
                    </label>
                </Stack>

                <img
                    id={`visual-project-${project.title}`}
                    src={project.url}
                    alt={`${project.title}, ${project.medium}`}
                />

                <Stack
                    className="visual-project-palette"
                    direction="row"
                    position="absolute"
                    bottom={0}
                    right={0}
                >
                    {renderPalette()}
                </Stack>
            </div>
        </Grid>
    );
};

export default VisualProjectCard;
