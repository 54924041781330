import {
    createTheme,
    ThemeProvider as MUIThemeProvider,
    PaletteMode,
} from '@mui/material';
import React, { createContext, useContext, useMemo, useState } from 'react';

import HexCursorWhite from '../assets/images/hex-cursor-white.webp';
import HexLogoWhite from '../assets/images/hex-logo-white.webp';
import colors from '../assets/scss/_colors.scss';
import { theme } from '../assets/Theme';

export const ColorModeContext = createContext({ toggleColorMode: () => {} });
export const useColorMode = () => useContext(ColorModeContext);

export type ThemeMode = 'dark' | 'light' | 'blackout';

export const resetProperties = (properties: string[]) => {
    return properties.forEach((p) => {
        document.documentElement.style.removeProperty(p);
    });
};

export const toggleTheme = (mode: ThemeMode) => {
    switch (mode) {
        case 'dark':
            break;
        case 'light':
            document.documentElement.style.setProperty(
                '--logo',
                `url(${HexLogoWhite})`
            );
            document.documentElement.style.setProperty(
                '--hex-cursor',
                `url(${HexCursorWhite})`
            );
            document.documentElement.style.setProperty(
                '--color-background',
                'black'
            );
            document.documentElement.style.setProperty(
                '--color-primary-500',
                'white'
            );
            break;
        case 'blackout':
            document.documentElement.style.setProperty(
                '--logo',
                `url(${HexLogoWhite})`
            );
            document.documentElement.style.setProperty(
                '--hex-cursor',
                `url(${HexCursorWhite})`
            );
            document.documentElement.style.setProperty(
                '--color-background',
                'black'
            );
            document.documentElement.style.setProperty(
                '--color-primary-500',
                'white'
            );
            break;
    }
};

export const ThemeProvider = ({ children }: any) => {
    const [mode, setMode] = useState<PaletteMode>('dark');

    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) =>
                    prevMode === 'light' ? 'dark' : 'light'
                );
                toggleTheme(mode as ThemeMode);
            },
        }),
        []
    );

    const _theme = useMemo(
        () =>
            createTheme({
                ...theme,
                palette: {
                    mode: mode,
                },
            }),
        [mode]
    );

    return (
        <ColorModeContext.Provider value={colorMode}>
            <meta name="theme-color" content={colors['bg']} />
            <MUIThemeProvider theme={_theme}>{children}</MUIThemeProvider>
        </ColorModeContext.Provider>
    );
};
