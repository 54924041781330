export type FactCategory = 'frameworks' | 'interests' | 'languages' | 'tools';

export type Fact = {
    category: FactCategory;
    items: string[];
};

export type ProjectType = 'dev' | 'visual';

export type Project = {
    title: string;
    url?: string;
    urlTitle?: string;
    description?: JSX.Element | string;
};

export interface DevProject extends Project {
    source?: string;
    preview: string;
    skills: string[];
}

export enum Medium {
    Digital = 'digital',
    PenOnPaper = 'pen on paper',
}

export interface VisualProject extends Project {
    url: string;
    medium: Medium;
    tools: string;
    timestamp: Date;
}
