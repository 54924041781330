import React from 'react';
import AmITheOnlyOnePreview from '../assets/images/projects/dev/previews/amitheonlyone.webp';
import AnoniPreview from '../assets/images/projects/dev/previews/anoni.webp';
import BreakroomPreview from '../assets/images/projects/dev/previews/breakroom.webp';
import ClevercatPreview from '../assets/images/projects/dev/previews/clevercat.webp';
import IntellitermPreview from '../assets/images/projects/dev/previews/intelliterm.webp';
import LightbulbPreview from '../assets/images/projects/dev/previews/lightbulb.webp';
import UXIPreview from '../assets/images/projects/dev/previews/uxi.webp';
import VerbalizePhotosPreview from '../assets/images/projects/dev/previews/verbalizephotos.webp';
import FreeAssociationImage from '../assets/images/projects/visual/free-association.jpg';
import IntrusiveThoughtsImage from '../assets/images/projects/visual/intrusive-thoughts.jpg';
import KotikImage from '../assets/images/projects/visual/kotik.jpg';
import MirrorOfMindImage from '../assets/images/projects/visual/mirror-of-mind.jpg';
import ObeyImage from '../assets/images/projects/visual/obey.jpg';
import SweetCyanideImage from '../assets/images/projects/visual/sweet-cyanide.jpg';
import RoseImage from '../assets/images/projects/visual/rose.jpg';
import UntitledImage from '../assets/images/projects/visual/untitled.jpg';
import WaitingRoomImage from '../assets/images/projects/visual/waiting-room.jpg';

import { Button, Stack } from '@mui/material';
import {
    Medium,
    type DevProject,
    type Project,
    type ProjectType,
    type VisualProject,
} from '../utils/Types';
import { githubRepo, My } from './My';

class Projects {
    readonly devProjects: DevProject[] = [
        {
            title: 'Intelliterm',
            url: `https://github.com/${My.github}/intelliterm`,
            preview: IntellitermPreview,
            description: (
                <span>
                    <strong>Chat with AI</strong> from your{' '}
                    <strong>terminal!</strong> Intelliterm is an{' '}
                    <strong>open-source</strong> CLI tool that comes packed with
                    a variety of features including{' '}
                    <strong>chat management,</strong>{' '}
                    <strong>code execution,</strong>{' '}
                    <strong>GPT-3 & GPT-4</strong> and more!
                </span>
            ),
            skills: [
                'Python',
                'pytest',
                'openai',
                'CLI',
                'Machine Learning',
                'artificial intelligence',
            ],
        },
        {
            title: 'UXInsight',
            url: `${My.portfolio}/blob/main/projects/uxi.md`,
            preview: UXIPreview,
            description: (
                <span>
                    <strong>User experience analysis</strong> system that
                    extracts <strong>hidden insights</strong> and{' '}
                    <strong>intent</strong> from <strong>user behavior.</strong>{' '}
                </span>
            ),
            skills: [
                'TypeScript',
                'Next.js',
                'React',
                'D3.js',
                'MongoDB',
                'jest',
                'Material-UI',
                'Sass',
            ],
        },
        {
            title: 'Clevercat',
            url: `${My.portfolio}/blob/main/projects/clevercat.md`,
            preview: ClevercatPreview,
            description: (
                <span>
                    <strong>Feature-rich Q&A system,</strong> complete with{' '}
                    <strong>authentication,</strong>{' '}
                    <strong>full-text search,</strong> search result{' '}
                    <strong>filtering,</strong> <strong>SQLi mitigation</strong>{' '}
                    and a <strong>karma system</strong> (among others).
                </span>
            ),
            skills: [
                'TypeScript',
                'React',
                'PostgreSQL',
                'Node.js',
                'Material-UI',
                'Sass',
            ],
        },
        {
            title: 'verbalize.photos',
            url: `${My.portfolio}/blob/main/projects/verbalize.md`,
            preview: VerbalizePhotosPreview,
            description: (
                <span>
                    <strong>Helps visually-impaired (VI)</strong> users{' '}
                    <strong>experience visual content</strong> with the help of{' '}
                    <strong>artificial intelligence (AI)</strong>.
                </span>
            ),
            skills: [
                'AWS',
                'Azure',
                'TypeScript',
                'React',
                'Node.js',
                'Firebase',
                'Material-UI',
                'Sass',
            ],
        },
        {
            title: 'Lightbulb',
            url: githubRepo('Lightbulb'),
            preview: LightbulbPreview,
            description: (
                <span>
                    Minimal <strong>dark theme</strong> that{' '}
                    <strong>intelligently illuminates</strong> the{' '}
                    <strong>important stuff</strong> 💡
                    <br />
                    <br />
                    <Stack
                        alignItems={{ xs: 'center', md: 'flex-start' }}
                        style={{ fontSize: '14px' }}
                    >
                        <Button
                            aria-label="download lightbulb button"
                            href="https://marketplace.visualstudio.com/items?itemName=ykray.lightbulb"
                            target="_blank"
                        >
                            Install for VS Code
                        </Button>
                    </Stack>
                </span>
            ),
            skills: ['Theme Design', 'Visual Studio Code development'],
        },
        {
            title: 'Anoni',
            url: `${My.portfolio}/blob/main/projects/anoni.md`,
            preview: AnoniPreview,
            description: (
                <span>
                    <strong>Anonymous social platform</strong> supporting{' '}
                    <strong>authentication,</strong> end-to-end{' '}
                    <strong>encrypted chats,</strong> and{' '}
                    <strong>user discovery</strong> (among other features).
                </span>
            ),
            skills: [
                'AWS',
                'TypeScript',
                'React',
                'React Native',
                'Node.js',
                'Firebase',
                'Cryptography',
                'Material-UI',
                'Sass',
            ],
        },
        {
            title: 'Breakroom',
            url: `${My.portfolio}/blob/main/projects/breakroom.md`,
            preview: BreakroomPreview,
            description: (
                <span>
                    <strong>Hyperlocal random group chat</strong> cross-platform
                    app for <strong>web and mobile</strong> supporting{' '}
                    <strong>authentication,</strong> locally-trending{' '}
                    <strong>content discovery,</strong> and{' '}
                    <strong>gamified microeconomy.</strong>
                    <br />
                    <br />
                    (co-founded with{' '}
                    <a href="https://yegor.codes">Yegor Chernyshev</a>)
                </span>
            ),
            skills: [
                'TypeScript',
                'React',
                'React Native',
                'expo',
                'nx',
                'jest',
                'Firebase',
                'Material-UI',
                'Sass',
            ],
        },
        {
            title: 'Am I the only one?',
            url: `${My.portfolio}/blob/main/projects/am-i-the-only-one.md`,
            preview: AmITheOnlyOnePreview,
            description: (
                <span>
                    <strong>A public poll</strong> for the{' '}
                    <strong>Internet's deep, weird</strong> (and otherwise
                    2am-ish) <strong>questions.</strong> A fun little project to
                    remind the world that{' '}
                    <strong>
                        we're all more alike than we are different 🙃.
                    </strong>
                </span>
            ),
            skills: ['TypeScript', 'React', 'Node.js', 'Firebase'],
        },
    ];

    readonly visualProjects: VisualProject[] = [
        {
            title: 'Waiting Room',
            medium: Medium.PenOnPaper,
            tools: 'pen, markers',
            url: WaitingRoomImage,
            timestamp: new Date(2024, 2, 4),
        },
        {
            title: 'Untitled',
            medium: Medium.PenOnPaper,
            tools: 'Sharpie & Pen',
            url: UntitledImage,
            timestamp: new Date(2023, 1, 10),
        },
        {
            title: 'Rose',
            medium: Medium.PenOnPaper,
            tools: 'Sharpie & Pen',
            url: RoseImage,
            timestamp: new Date(2021, 10, 10),
        },
        {
            title: 'Kotik',
            medium: Medium.PenOnPaper,
            tools: 'Sharpie & Pen',
            url: KotikImage,
            timestamp: new Date(2020, 2, 14),
        },
        {
            title: 'Sweet Cyanide',
            medium: Medium.Digital,
            tools: 'Photoshop, Blender',
            url: SweetCyanideImage,
            timestamp: new Date(2019, 10, 8),
        },
        {
            title: 'Field Theory',
            medium: Medium.Digital,
            tools: 'Photoshop',
            url: MirrorOfMindImage,
            timestamp: new Date(2019, 9, 15),
        },
        {
            title: 'Intrusive Thoughts',
            medium: Medium.Digital,
            tools: 'Photoshop',
            url: IntrusiveThoughtsImage,
            timestamp: new Date(2019, 7, 16),
        },
        {
            title: 'Repeat After Me',
            medium: Medium.Digital,
            tools: 'Photoshop',
            url: ObeyImage,
            timestamp: new Date(2021, 10, 17),
        },
        {
            title: 'Disassociation',
            medium: Medium.Digital,
            tools: 'Photoshop, Blender',
            url: FreeAssociationImage,
            timestamp: new Date(2019, 10, 16),
        },
    ];

    get = (type: ProjectType): Project[] => {
        switch (type) {
            case 'dev':
                return this.devProjects;
            case 'visual':
                return this.visualProjects;
        }
    };
}

export default new Projects();
