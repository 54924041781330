import React from 'react';
import Helmet from 'react-helmet';

import { Stack } from '@mui/material';
import BackToTop from '../../BackToTop';
import { Divider } from '../../Divider';
import Footer from '../../Footer';
import Hero from '../../Hero';
import DevProjectsView from './dev/DevProjectsView';
import VisualProjectsView from './visual/VisualProjectsView';

const WorkScreen = () => {
    return (
        <>
            <Helmet>
                <title>Yulian Kraynyak | Work</title>
                <meta
                    property="og:title"
                    content="Yulian Kraynyak | My Work"
                    data-react-helmet="true"
                />
                <meta
                    name="description"
                    content="My personal interests revolve around programming, art, and electronic music."
                    data-react-helmet="true"
                />
                <meta
                    property="og:image"
                    content="../../public/resources/meta-image.jpg"
                    data-react-helmet="true"
                />
            </Helmet>

            <Hero
                header={
                    <h1 style={{ position: 'relative' }}>
                        I like <strong>making things.</strong>
                    </h1>
                }
                body={<></>}
            />

            <DevProjectsView />
            <Divider />
            <Hero
                header={<></>}
                body={
                    <Stack direction="column" spacing={2} alignItems="center">
                        <p>
                            I also like to draw
                        </p>
                    </Stack>
                }
            />
            <VisualProjectsView />

            <Footer />
            <BackToTop />
        </>
    );
};

export default WorkScreen;
