import { Grid } from '@mui/material';
import React from 'react';
import { isMobile } from 'react-device-detect';

import Projects from '../../../../data/Projects';
import type { VisualProject } from '../../../../utils/Types';
import VisualProjectCard from './VisualProjectCard';

const VisualProjectsView = () => {
    return (
        <div id="visual-projects-view" className="visual-projects-view">
            <Grid
                container
                alignItems="flex-start"
                justifyContent="flex-start"
                spacing={isMobile === true ? 2 : 6}
            >
                {Projects.get('visual').map((p, i) => (
                    <VisualProjectCard project={p as VisualProject} key={i} />
                ))}
            </Grid>
        </div>
    );
};

export default VisualProjectsView;
