export type Info = {
    name: string;
    email: string;
    portfolio: string;
    resume: string;
    github: string;
    linkedin: string;
};

export const GITHUB_USERNAME = 'ykrx';
export const LINKEDIN_USERNAME = 'ykrx';

export const My: Info = {
    name: 'Yulian Kraynyak',
    email: 'yulian@yulian.codes',
    resume: 'https://drive.google.com/file/d/1Bctbrml9RGyIbULPcJRRggmM6D9iFVSQ/view?usp=sharing',
    portfolio: `https://github.com/${GITHUB_USERNAME}/portfolio`,
    github: GITHUB_USERNAME,
    linkedin: LINKEDIN_USERNAME,
};

export const githubRepo = (repo: string) => {
    return `https://github.com/${GITHUB_USERNAME}/${repo}`
}
