import { LogoGithub } from '@carbon/icons-react';
import { Article } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';
import { My } from '../../../data/My';
import BackToTop from '../../BackToTop';
import Footer from '../../Footer';
import Hero from '../../Hero';

const AboutScreen = () => {
    return (
        <>
            <Helmet>
                <title>{My.name} | Software Engineer</title>
                <meta
                    name="title"
                    content="Yulian Kraynyak | Software Engineer"
                    data-react-helmet="true"
                />
                <meta
                    name="description"
                    content="I'm Yulian Kraynyak, a Machine Learning Engineer based in NYC."
                    data-react-helmet="true"
                />
                <meta
                    property="og:image"
                    content="../../public/resources/meta-image.jpg"
                    data-react-helmet="true"
                />
            </Helmet>

            <Hero
                header={
                    <>
                        <h1>
                            I'm <strong>Yulian,</strong> a{' '}
                            <strong>
                                <em>software engineer</em>
                            </strong>{' '}
                            at <strong>Amazon</strong>
                        </h1>
                    </>
                }
                body={<></>}
            />

            <p>
                Based in <strong>Brooklyn, NY</strong> with an{' '}
                <strong>MS CS</strong> from <strong>NYU.</strong> I'm either{' '}
                <strong>coding, drawing</strong> or{' '}
                <strong>playing with synthesizers.</strong>
            </p>

            <Stack
                direction="row"
                spacing={2}
                justifyContent="stretch"
                style={{ marginTop: 60, marginBottom: 35 }}
            >
                <Button
                    aria-label="resume button"
                    href={My.resume}
                    target="_blank"
                    startIcon={<Article />}
                >
                    Resume
                </Button>
                <Button
                    aria-label="portfolio button"
                    href={My.portfolio}
                    target="_blank"
                    startIcon={<LogoGithub />}
                >
                    Portfolio
                </Button>
            </Stack>

            <Footer />
            <BackToTop />
        </>
    );
};

export default AboutScreen;
