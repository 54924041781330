import { Stack } from '@mui/material';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import HeaderNav from './nav/HeaderNav';

const Header = () => {
    const location = useLocation();

    return (
        <div id="header" className="header">
            <Stack
                direction="column"
                spacing={6}
                justifyContent={{ xs: 'flex-start', md: 'space-between' }}
                alignItems={{ xs: 'center', md: 'center' }}
            >
                <Stack
                    spacing={1.4}
                    direction="column"
                    alignItems={{ xs: 'center', md: 'center' }}
                >
                    <Link to="/" state={{ from: location.pathname }}>
                        <div className="logo" />
                    </Link>
                </Stack>
                <HeaderNav />
            </Stack>
        </div>
    );
};

export default Header;
