import { Stack } from '@mui/material';
import React from 'react';
import Logo from '../assets/images/hex-logo.webp';
import { scrollToTop } from '../utils/Utils';

type Props = {
    interactive?: boolean;
};
export const Divider = ({ interactive = false }: Props) => {
    return interactive ? (
        <Stack
            className="divider"
            direction="row"
            alignItems="center"
            justifyContent="stretch"
            spacing={3}
        >
            <div className="line" />
            <img src={Logo} onClick={scrollToTop} />
            <div className="line" />
        </Stack>
    ) : (
        <Stack
            className="divider"
            direction="row"
            alignItems="center"
            justifyContent="stretch"
            spacing={3}
        >
            <div className="line" />
            <img src={Logo} />
            <div className="line" />
        </Stack>
    );
};
