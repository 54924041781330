import { Button, Grid, Stack } from '@mui/material';
import React from 'react';

import { LogoGithub } from '@carbon/icons-react';
import { GITHUB_USERNAME, My } from '../../../../data/My';
import Projects from '../../../../data/Projects';
import type { DevProject } from '../../../../utils/Types';
import DevProjectCard from './DevProjectCard';

const DevProjectsView = () => {
    return (
        <>
            <Stack alignItems={{ xs: 'center', md: 'flex-start' }}>
                <Button
                    aria-label="portfolio button"
                    href={My.portfolio}
                    target="_blank"
                    startIcon={<LogoGithub />}
                >
                    {GITHUB_USERNAME}/portfolio
                </Button>
            </Stack>

            <div className="dev-projects-view">
                <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    direction="row"
                    spacing="15px"
                >
                    {Projects.get('dev').map((p, i) => (
                        <DevProjectCard project={p as DevProject} key={i} />
                    ))}
                </Grid>
            </div>
        </>
    );
};

export default DevProjectsView;
